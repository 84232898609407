
.custom-list2 {
    list-style: none;
    padding: 0;
  }
  
  .custom-list2 li {
    position: relative;
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 10px; /* Adjust as needed */
  }
  
  .custom-list2 li::before {
    content: '\2713'; /* Unicode character for 'tick' symbol */
    font-size: 20px; /* Adjust as needed */
    color: orange; /* Adjust the color as needed */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }