
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: gray #ffffff;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: gray;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  
 .flip{
  transform: scaleX(-1);
 }

 .background-update{
  background-image: url('../../Assets/updatebg.png');
  /* background-image: url('../../Assets/coursesimg/programing1.jpg'); */
  background-size: contain;
 }

 .btnnormal {
  --color: #21a5d8;
  font-family: inherit;
  display: inline-block;
  width: fit-content;
  padding-right: 7px;
  padding-left: 7px;
  height: 2.1em;
  /* line-height: 2.5em; */
  /* margin: 20px; */
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color .5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
  background-color: white;
  
 }

 .btnnormal:hover{
  background-color: var(--color);
  color: white;
 }

 .cusf4{
  flex: 0.4;
 }
 .cusf5{
  flex: 0.5;
 }


 @keyframes blink {
  0% {
    opacity: 1;
    color: rgb(243, 12, 50);
    transform: scale(1.1);
  }
  50% {
    opacity: 0.5;
    color: rgb(232, 18, 118);
    transform: scale(1.6);
  }
  100% {
    opacity: 1;
    color: rgb(243, 12, 50);
    transform: scale(1);
  }
}

.blinking-text {
  animation: blink 1s infinite; /* Adjust the animation duration as needed */
}