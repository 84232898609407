@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

.abel{
    font-family: 'Abel', sans-serif;
    font-size: 1.1rem;
}

.underlinehover:hover{
     text-decoration: underline;
     height: 48px;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  
  .hover-underline-animation:hover {
    background-color: unset !important;
    transform: scale(1.09);
    /* font-size: 1.2rem; */
  }
  
  .hover-underline-animation::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #06213a;
    background-image: linear-gradient(to right, #0e3050, #000102);
    transform-origin: left bottom right;
    transition: transform 0.5s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: left bottom right;
  }
  