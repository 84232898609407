.search-box{
    border: none;
    border-bottom: 1px solid #aaa;
    background: transparent;
    outline: none;
    height: 30px;
    width: 250px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    transition: .5s;
}

.search-box:focus{
    width: 300px;
    border-color: #1f80e0;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&display=swap');
.barlow{
    letter-spacing: 2px;
    font-family: 'Barlow Condensed', sans-serif;
    /* font-family: "cera medium"; */
}

@import url('https://fonts.googleapis.com/css2?family=Young+Serif&display=swap');

.logo{
    font-family: 'Young Serif', serif;
}

.fontcus{
    font-size: calc(0.9em + 2vw);
    /* border: 2px solid red; */
    text-align: center;
    width: 100%;
}

.sape{
    width: calc(100% - 50px);
height: 100%;
}