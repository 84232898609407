.background{
    background-image: url('../../Assets/nnnoise.svg');
    background-size:contain ;
    /* background-color: aqua; */
}

.backgr-hero{
    /* background-image: url('../../Assets/Banner-01.jpg'); */
    background-image: url('../../Assets/bg6.png');
    background-size: cover;
    /* background-repeat: no-repeat; */
}

.btnhover {
    --color: #627bc4;
    font-family: inherit;
    display: inline-block;
    width: 8em;
    height: 2.6em;
    line-height: 2.5em;
    /* margin: 20px; */
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 6px;
    font-weight: 500;
    color: var(--color);
   }
   
   .btnhover:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
   }
   
   .btnhover:hover {
    color: #fff;
   }
   
   .btnhover:before {
    top: 100%;
    left: 100%;
    transition: all .5s;
   }
   
   .btnhover:hover:before {
    top: -30px;
    left: -30px;
   }
   
   .btnhover:active:before {
    background: var(--color);
   }

 